<template>
  <div>
    <app-list request-url="/api/teams/table" entity="teams">
      <template #body="{item: team}">
        <children-symbols entity="blocks" :children="team.blocks" popover-field="full_name"/>
        <children-symbols entity="users" :children="team.users" with-acronym field="full_name"/>
      </template>
    </app-list>
    <portal to="subheader-toolbar">
      <b-button variant="transparent-white" @click="$router.push({name: 'createTeam'})">
        {{ $t('btn.create_team') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppList from '@/components/lists/AppList';
import ChildrenSymbols from '@/components/lists/components/ChildrenSymbols';

export default {
  components: {
    AppList,
    ChildrenSymbols
  }
}
</script>